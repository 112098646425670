<template>
  <div class="div_set_phone">
    <h3 class="h3_title">绑定手机号</h3>
    <p class="p_explain">保证账户的安全及唯一性</p>
    <div class="div_input_body">
      <InputPhone
        ref="phone"
        style="margin-bottom: 20px"
        v-model="newObj.mobile"
      ></InputPhone>
      <InputCode
        ref="code"
        v-model="newObj.sms_secret"
        :getCode="getCode"
      ></InputCode>
    </div>
    <button
      type="button"
      class="butsubmit"
      :class="canSubmit() ? 'btnGroupOne' : 'btnGroupNone'"
      @click="clickSubmit"
    >
      登录/注册
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import InputPhone from "@/components/input/inputPhone.vue";
import InputCode from "@/components/input/inputCode.vue";
// import { sendMessage, wxLoginNumber } from "@/api/login";
import { getLocalStor, setLocalStor, goBackToSource } from "@/utils/index";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

import {
  sendValidateCode,
  loginByValidateCode,
  checkMobileExists,
} from "@/api/api.js";

const router = useRouter();

let newObj = ref({
  mobile: "",
  sms_key: "",
  sms_secret: "", //验证码
});

// --ref---
let phone = ref(null);
let code = ref(null);

let userWx = JSON.parse(localStorage.getItem("wxLogin"));
console.log(getLocalStor("wxLogin"));
if (!userWx) {
  router.replace("wxLogin");
}

// 验证
function verification() {
  let isOk = true;
  let worrIng = "";
  // 验证码登录
  if (newObj.value.mobile == "") {
    isOk = false;
    phone.value.worring = true;
    worrIng = "请输入手机号";
  } else if (newObj.value.mobile.length != 11) {
    isOk = false;
    phone.value.worring = true;
    worrIng = "请输入正确的手机号";
  } else if (newObj.value.sms_secret == "") {
    isOk = false;
    code.value.worring = true;
    worrIng = "请输入验证码";
  }

  if (!isOk)
    ElMessage({
      message: worrIng,
      type: "error",
    });
  return isOk;
}
// 点击获取验证码
function getCode(callback) {
  if (newObj.value.mobile.length == "") {
    phone.value.worring = true;
    ElMessage({
      message: "请输入手机号",
      type: "error",
    });
    return;
  } else if (newObj.value.mobile.length != 11) {
    phone.value.worring = true;
    ElMessage({
      message: "请输入正确的手机号",
      type: "error",
    });
    return;
  }
  sendValidateCode(newObj.value.mobile, 1).then(() => {
    callback(true);
    ElMessage({
      message: "验证码已发送",
      type: "message",
    });
  });
}
function canSubmit() {
  let isok = true;
  for (const key in newObj.value) {
    if (newObj.value[key] == "" && key != "sms_key") {
      isok = false;
      break;
    }
  }
  return isok;
}
// 点击绑定手机号
function clickSubmit() {
  if (!verification()) return;
  // 判断新老用户
  checkMobileExists(newObj.value.mobile).then((result) => {
    loginByValidateCode(newObj.value.mobile, newObj.value.sms_secret, 1).then(
      (res) => {
        if (res.code == 200) {
          if (result.data) {
            ElMessage({
              icon: "SuccessFilled",
              type: "success",
              message: "登录成功",
            });
            setTimeout(() => {
              localStorage.clear();
              goBackToSource(res.data);
            }, 1000);
          } else {
            setLocalStor("userLogin", {
              phone: newObj.value.mobile,
              token: res.data,
            });
            //补充个人信息
            router.replace({ name: "setMessage" });
          }
        } else {
          ElMessage({
            type: "error",
            message: res.message,
          });
        }
      }
    );
  });
}
</script>

<style lang='scss' scoped>
.div_set_phone {
  padding: 40px;
  text-align: center;
  .p_explain {
    margin: 8px 0px 32px;
  }
  .div_input_body {
    text-align: left;
    .flex_between {
      margin-top: 16px;
      line-height: 1;
      font-size: 14px;
      color: #1f2328;
    }
  }
  .butsubmit {
    width: 100%;
    height: 60px;
    margin: 24px 0px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
