<template>
  <div class="div_input_mobile flex" :class="worring ? 'input_worring' : ''">
    <span>+86</span>
    <i></i>
    <el-input v-model="mobile" @focus="worring = false" size="large" maxlength="11" style="margin-top: 0px"
      class="key_number" :controls="false" @input="inputPhone" placeholder="请输入手机号" />
  </div>
</template>

<script setup>
  import {
    defineProps,
    defineEmits,
    ref,
    onMounted,
    defineExpose
  } from "vue";

  const props = defineProps(["modelValue"]);
  const emit = defineEmits(["update:modelValue"]);

  let mobile = ref("");
  let worring = ref(false);

  onMounted(() => {
    mobile.value = props.modelValue;
  });

  function inputPhone(value) {
    mobile.value = value
    emit("update:modelValue", mobile.value);
  }

  defineExpose({
    worring,
  });
</script>

<style lang='scss' scoped>
  .div_input_mobile {
    height: 60px;
    background: #f6f6f7;
    border-radius: 10px 10px 10px 10px;
    padding: 0px 16px;
    border: 1px solid transparent;
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;
    transition: all $anim-time-fn-ease-out 0.25s;

    >span {
      font-size: 16px;
      font-weight: 600;
      color: #1f2328;
    }

    >i {
      display: inline-block;
      width: 1px;
      height: 23px;
      background-color: #d0d3d6;
      margin: 0px 16px;
    }

    >p {
      flex: 1;
      font-size: 16px;
    }
  }

  .key_number {
    height: 60px;
    margin-top: 24px;

    :deep(.el-input__wrapper) {
      border-radius: 10px;
      padding: 0px;
      box-shadow: none;
      background-color: #f6f6f7;

      input {
        height: 60px;
        font-size: 16px;
      }
    }
  }

  .input_worring {
    border: 1px solid #f56c6c;
  }
</style>
