<template>
  <div class="div_get_code" :class="worring ? 'input_worring' : ''">
    <el-input
      v-model="codeTitle"
      @focus="worring = false"
      size="large"
      maxlength="6"
      class="key_input"
      placeholder="输入验证码"
      @input="inputCode"
    />
    <p class="p_code">
      <span class="hover_color_t" v-if="!code.open" @click="clickGetCode">{{
        code.isFirst ? "获取验证码" : "重新获取"
      }}</span>
      <span v-else>{{ code.time + "s" }}</span>
    </p>
  </div>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineEmits,
  defineProps,
  onBeforeUnmount,
  onMounted,
} from "vue";

const props = defineProps(["modelValue", "getCode"]);
const emit = defineEmits(["update:modelValue"]);

let codeTitle = ref("");
let worring = ref(false);

onMounted(() => {
  codeTitle.value = props.modelValue;
});

// 倒计时
let code = ref({
  open: false,
  time: 60,
  interval: null,
  isFirst: true, //是否首次获取验证码
});

function inputCode(value) {
  codeTitle.value = value
  emit("update:modelValue", codeTitle.value);

}
// 获取验证码
function clickGetCode() {
  props.getCode((cando) => {
    if (cando) {
      code.value.open = true;
      setDownLoad();
    }
  });
}
// 设置倒计时
function setDownLoad() {
  code.value.interval = setInterval(() => {
    code.value.time--;
    if (code.value.time == 0) {
      code.value.open = false;
      code.value.time = 60;
      code.value.isFirst = false;
      clearInterval(code.value.interval);
      code.value.interval = null;
    }
  }, 1000);
}
onBeforeUnmount(() => {
  if (code.value.time) {
    clearInterval(code.value.time);
    code.value.time = null;
  }
});

defineExpose({
  worring,
});
</script>

<style lang='scss' scoped>
.div_get_code {
  border: 1px solid transparent;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  transition: all $anim-time-fn-ease-out 0.25s;
  .p_code {
    height: 60px;
    padding: 0px 24px;
    line-height: 60px;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #35ba9f;
    user-select: none;
    .hover_color_t {
      color: #35ba9f !important;
    }
  }
}
.input_worring {
  border: 1px solid #f56c6c;
}
</style>
